.md *
{
    @apply m-0 p-0;
}

.md a {
    @apply text-orange-main no-underline hover:underline
}

.md ul, .md li, .md p, .md h1, .md h2, .md h3, .md h4, .md h5, .md h6
{
    @apply mb-5 text-lg;
}

.md div{ 
    @apply text-lg;
}

.md h1, .md h2, .md h3 {
    @apply font-thin;
}

.md ul, .md li{
    list-style-position: inside;
}

.md div {
    @apply m-0;
}

.md p {
    @apply mb-8
}

.md h1 {
    @apply text-4xl text-center
}

.md h2 {
    @apply text-3xl
}

.md > * > img {
    @apply shadow-md mb-6 w-full rounded;
    max-height: calc(50vh + 180px);
}

.md video {
    @apply shadow-md mb-4 h-[50vh]
}
